.mailcontent {
    overflow: hidden; /* Hide overflowing content */
    max-height: 0; /* Start with height 0 */
    opacity: 0; /* Start with opacity 0 */
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out; /* Smooth transition */
  }
  
  .open {
    overflow-y: auto;
    max-height:600px; /* Adjust based on your content size */
    opacity: 1;
  }
  
  .close {
    max-height: 0; /* Collapses the height */
    opacity: 0;
  }
  