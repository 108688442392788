body {
  margin: 0;
  font-family: "Open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.editor ol{
 
  list-style-type: decimal;
  
}
.editor ul{
   list-style-type: disc;
  
}
::marker{
  margin-right: 0px
}
@tailwind base;
@tailwind components;
@tailwind utilities;
