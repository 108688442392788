/* .containerlog {
  margin-top: 50px;
  background-image: url("../public/login-background.jpg");
  width: 70%;
  box-sizing: border-box;
  margin: 50px auto;
  margin-left: 15%;
  padding: 40px;
}
.login-container {
  border: 1px solid white;
  background-color: white;
  width: 100%;

  display: flex;
  box-sizing: border-box;
}
.login-image {
  width: 45%;
}
.login-image img {
  width: 100%;
  height: 400px;
}
.login-form {
  padding: 20px;
  width: 55%;
  box-sizing: border-box;
}
.login-form input {
  padding: 7px;
  border: 1.5px solid #007bff;
  border-radius: 4px;
  width: 80%; */
/* margin-left: 10%; */
/* display: block;
  margin-bottom: 30px;
}
.login-button {
  box-sizing: border-box;
  width: 50%;
  font-size: 15px;
  border-radius: 50px;
  padding: 12px 8px;
  background-color: #007bff;
  border: 1px solid #007bff;
  color: white;
  height: 50px;
}
.login-button:hover {
  background-color: #007bff79;
}
.login-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 10px;
  height: 10px;

  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
} */

.spinner {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
