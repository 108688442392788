.editor-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.toolbar {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
}

.toolbar button {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 5px 10px;
    margin: 2px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
    transition: background-color 0.3s;
}

.toolbar button:hover {
    background-color: #eee;
}

.editor {
    min-height: 200px;
    text-align: left;
   
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    max-width: 100%;
}
.editor div{
    text-align: left;
}
#output {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
}
#editor img{
    width:100%;
  
}