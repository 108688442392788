.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 0.3rem 1.25rem; /* 10px top/bottom and 20px left/right equivalent */
    border-radius: 0.125rem; /* Rounded-sm equivalent */
  }
  
  
  
  .icon {
    font-size: 1.5rem; /* Adjust size as needed */
    /* Additional styles for the icon can go here */
  }
  
  .accordion-container {
    width: 70%;
    margin-left: 15%;
    display: flex;
    flex-direction: column;
    gap: 1.25rem; /* 5 equivalent in Tailwind, which is 1.25rem or 20px */
  }
  