.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #b5afafcc;
  z-index: 20;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 90%;
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: gray;
  cursor: pointer;
}

.close-button:hover {
  color: darkgray;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.icon-wrapper.success {
  color: green;
}

.icon-wrapper.error {
  color: red;
}

.icon {
  font-size: 108px;
  /* background-color: currentColor; */
  /* color: white; */
  padding: 20px;
  border-radius: 50%;
}

.message {
  text-align: center;
  font-size: 16px;
}

.message.success {
  color: green;
}

.message.error {
  color: red;
}
.normal {
  text-align: left;
}

.modal-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal-button.success {
  background-color: green;
}
.modal-button.message {
  background-color: #274cb2;
  color: white;
}

.modal-button.success:hover {
  background-color: darkgreen;
}

.modal-button.error {
  background-color: red;
}

.modal-button.error:hover {
  background-color: darkred;
}
