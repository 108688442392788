/* * {
  font-family: "Open Sans", sans-serif;
}

.body {
  width: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
  display: flex;
  box-sizing: border-box;
}
body {
  background-color: #ffffffcb;
  margin: 0;
  box-sizing: border-box;
}
.page-info {
  margin: 0;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.page-info a {
  text-decoration: none;
}
.page-info .page-title {
  font-size: 16px;
  font-weight: 600;
  color: #888;
}
.page-info .page-path {
  font-size: 14px;
  color: #007bff;
}
.page-path .active {
  color: #dddddd;
}
.sidebar {
  font-size: 13px;
  width: 250px;
  height: 100vh;
  background-color: #625e5e31;
  border: 1px solid #625e5e31;
  color: #333;
  display: flex;
  flex-direction: column;
  transition: width 0.3s;
  overflow: hidden;
  position: fixed;
  z-index: 1000;
  overflow-y: auto;
  padding-top: 10px;
}

.sidebar::-webkit-scrollbar {
  width: 6px; 
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.sidebar::-webkit-scrollbar-track {
  background-color: #f1f1f1; 
  border-radius: 10px; 
} */
/* .sidebar > img {
  width: 100px;
  margin-left: 20px;
}

.sidebar .heading {
  margin: 15px 0 0 20px;
  font-weight: 600;
  font-size: 14px;
}

.sidebar:hover {
  width: 250px;
}

.sidebar-header {
  padding: 20px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transition: padding 0.3s;
}

.sidebar:hover .sidebar-header {
  padding: 20px;
}

.sidebar-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  white-space: nowrap;
}

.sidebar-menu .first {
  margin-top: 3vh;
  margin-bottom: 2vh;
}

.sidebar-menu a {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  color: #000;
  text-decoration: none;
  overflow: hidden;
  transition: padding 0.3s;
}

.sidebar-menu a:hover {
  color: #007bff;
} */

/* .sidebar-menu li:hover {
    background-color: white;
} */

/* .sidebar-menu a i,
.sidebar-menu a img {
  margin-right: 10px;
  color: #444;
}

.sidebar-menu a.active {
  background-color: white;
} */
/* color: white; */

/* .dropdown-menu {
  display: none;
  list-style: none;
  padding: 0;
}

.dropdown-menu.show {
  display: block;
}

.sidebar-texts {
  display: block;
} */

/* .sidebar:hover .sidebar-menu .sidebar-texts {
    display: block;
    cursor: pointer;

} */

/* .main-content {
  flex: 1;
  padding: 20px;
  margin-left: 60px; 
  transition: margin-left 0.3s;
  margin-left: 250px; 
  position: relative;
}

.sidebar:hover ~ .main-content {
  margin-left: 250px; 
}

.header {
  margin-bottom: 20px;
  
  align-items: center;
  text-align: center;
} */
/* display: flex; */
/* nav {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}
nav > .date > p {
  color: #007bff;
} */
/* .profile img{ */
/* box-sizing: border-box; */
/* border-radius: 50%; */
/* border: 0.5px solid #999; */
/* padding: 15px; */
/* object-fit:contain; */
/* } */
/* .header h1 {
  margin: 0;
  font-size: 24px;
  color: #ff5733;
}

.header p {
  margin: 5px 0 0 0;
  color: #666;
}

.stats {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.card {
  align-items: center;
  box-sizing: border-box;
  width: 150px;
  background-color: #4c9bef;
  padding: 30px 10px;
  border-radius: 8px;
  
  text-align: center;
  margin: 0 10px;
  line-height: 6px;
  transition: padding 0.5s ease-in-out;
  cursor: pointer;
  color: white;
} */
/* height: 100px; */
/* flex: 1; */
/* .card:hover {
  padding: 30px 15px;
  box-sizing: content-box;
}

.card h3 {
  font-size: 13px;
} */
/* margin: 0 0 10px 0; */

/* .card p {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  text-shadow: #666;
}

.content {
  box-sizing: border-box;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.content h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recent-blogs {
  box-sizing: border-box;

  list-style-type: none;
  padding: 0;
  margin: 0;
}

.recent-blogs li {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px 20px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.recent-blogs li img {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  margin-right: 15px;
}

.recent-blogs .blog-info {
  flex: 1;
}

.recent-blogs .blog-info h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.recent-blogs .blog-info .meta {
  display: flex;
  align-items: center;
  color: #007bff;
  font-size: 14px;
}
.recent-blogs .blog-info .meta span:nth-child(2) {
  color: #ff5733;
}

.recent-blogs .blog-info .meta i {
  margin-right: 5px;
}

.recent-blogs .blog-info .meta span {
  margin-right: 15px;
}

.recent-blogs .edit {
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
}

.recent-blogs .edit:hover {
  text-decoration: underline;
}
.add-new-btn {
  background-color: white;
  color: #0056b3;
  padding: 2px 4px;
  border-radius: 3px;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
  border: 1px solid #0056b3;
  font-size: 13px;
}

.add-new-btn i {
  margin-right: 5px;
}

.add-new-btn:hover {
  background-color: #0056b3;
  color: white;
}
h2,
h3,
h4,
p {
  font-weight: 500;
}
.sidebar .sidebar-menu .dropdown-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.sidebar .sidebar-menu .open .dropdown-menu {
  max-height: 200px;
}

.sidebar .sidebar-menu .dropdown-menu li a {
  padding: 8px 10px;
  margin-left: 15px;
  margin-right: 15px;
} */
/* BlogList.css */
/* .data-table .postimage {
  width: 100%;
  height: 18px;
}
.data-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 14px;
  margin-left: 0%;
} */
/* text-align: left; */

/* .data-table thead tr {
  background-color: #4889ca;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
}

.data-table th,
.data-table td {
  padding: 7px 5px;
  border: 1px solid #dddddd;
}

.data-table tbody tr {
  border-bottom: 1px solid #dddddd;
  box-sizing: border-box;
}
.data-table tbody tr td {
  max-width: 200px;
  word-wrap: break-word;
}
.data-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.data-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.data-table .postimage {
  max-width: 100px;
  height: auto;
}
.editimg {
  width: 13px;
}
#footer {
  margin-top: 50px;
}
footer {
  width: 100%;
  
  padding: 10px;
  text-align: center;
  border-top: 1px solid #625e5e31;
  box-sizing: border-box;
  margin: 0;
  font-size: 14px;
} */
/* bottom: 0; */

/* .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  cursor: pointer;
}

.pagination button.active {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.pagination button:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
  background-color: #e9ecef;
}

.add-item {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #007bff;
  border: 1px solid #007bff;
  color: white;
  font-size: 14px;
} */

/* *************************************************************************************************************************** */
* {
  font-family: "Open Sans", sans-serif;
}
.sidebar {
  overflow-y: auto;
}

.sidebar::-webkit-scrollbar {
  width: 6px;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.sidebar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}
.body {
  width: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
  display: flex;
  box-sizing: border-box;
}
body {
  background-color: #ffffffcb;
  margin: 0;
  box-sizing: border-box;
}
.page-info {
  margin: 0;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.page-info a {
  text-decoration: none;
}
.page-info .page-path {
  color: #007bff;
}
.page-path .active {
  color: #dddddd;
}
/* .sidebar {
  font-size: 13px;
  width: 250px;
  height: 100vh;
  background-color: #625e5e31;
  border: 1px solid #625e5e31;
  color: #333;
  display: flex;
  flex-direction: column;
  transition: width 0.3s;
  overflow: hidden;
  position: fixed;
  z-index: 1000;
  overflow-y: auto;
  padding-top: 10px;
}

.sidebar::-webkit-scrollbar {
  width: 6px; 
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.sidebar::-webkit-scrollbar-track {
  background-color: #f1f1f1; 
  border-radius: 10px; 
}
.sidebar > img {
  width: 100px;
  margin-left: 20px;
}

.sidebar .heading {
  margin: 15px 0 0 20px;
  font-weight: 600;
  font-size: 14px;
}

.sidebar:hover {
  width: 250px;
}

.sidebar-header {
  padding: 20px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transition: padding 0.3s;
}

.sidebar:hover .sidebar-header {
  padding: 20px;
}

.sidebar-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  white-space: nowrap;
}

.sidebar-menu .first {
  margin-top: 3vh;
  margin-bottom: 2vh;
}

.sidebar-menu a {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  color: #000;
  text-decoration: none;
  overflow: hidden;
  transition: padding 0.3s;
}

.sidebar-menu a:hover {
  color: #007bff;
}

.sidebar-menu a i,
.sidebar-menu a img {
  margin-right: 10px;
  color: #444;
}

.sidebar-menu a.active {
  background-color: white;
}

.dropdown-menu {
  display: none;
  list-style: none;
  padding: 0;
}

.dropdown-menu.show {
  display: block;
}

.sidebar-texts {
  display: block;
} */

/* .main-content {
  flex: 1;
  padding: 20px;
  margin-left: 60px;
  transition: margin-left 0.3s;
  margin-left: 250px;
  position: relative;
} */

.sidebar:hover ~ .main-content {
  margin-left: 250px; /* Adjust based on sidebar width */
}

.header {
  /* display: flex; */
  margin-bottom: 20px;

  align-items: center;
  text-align: center;
}
/* nav {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}
nav > .date > p {
  color: #007bff;
} */
.header h1 {
  margin: 0;
  font-size: 24px;
  color: #ff5733;
}

.header p {
  margin: 5px 0 0 0;
  color: #666;
}

.stats {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.card {
  align-items: center;
  box-sizing: border-box;
  width: 150px;
  /* height: 100px; */
  background-color: #4c9bef;
  padding: 30px 10px;
  border-radius: 8px;

  text-align: center;
  /* flex: 1; */
  margin: 0 10px;
  line-height: 6px;
  transition: padding 0.5s ease-in-out;
  cursor: pointer;
  color: white;
}
.card:hover {
  padding: 30px 15px;
  box-sizing: content-box;
}

.card h3 {
  /* margin: 0 0 10px 0; */
  font-size: 13px;
}

.card p {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  text-shadow: #666;
}

.content {
  box-sizing: border-box;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.content h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recent-blogs {
  box-sizing: border-box;

  list-style-type: none;
  padding: 0;
  margin: 0;
}

.recent-blogs li {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px 20px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.recent-blogs li img {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  margin-right: 15px;
}

.recent-blogs .blog-info {
  flex: 1;
}

.recent-blogs .blog-info h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.recent-blogs .blog-info .meta {
  display: flex;
  align-items: center;
  color: #007bff;
  font-size: 14px;
}
.recent-blogs .blog-info .meta span:nth-child(2) {
  color: #ff5733;
}

.recent-blogs .blog-info .meta i {
  margin-right: 5px;
}

.recent-blogs .blog-info .meta span {
  margin-right: 15px;
}

.recent-blogs .edit {
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
}

.recent-blogs .edit:hover {
  text-decoration: underline;
}
.add-new-btn {
  background-color: white;
  color: #0056b3;
  padding: 2px 4px;
  border-radius: 3px;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
  border: 1px solid #0056b3;
  font-size: 13px;
}

.add-new-btn i {
  margin-right: 5px;
}

.add-new-btn:hover {
  background-color: #0056b3;
  color: white;
}
h2,
h3,
h4,
p {
  font-weight: 500;
}
.sidebar .sidebar-menu .dropdown-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.sidebar .sidebar-menu .open .dropdown-menu {
  max-height: 200px; /* Adjust this value based on the height of your dropdown content */
}

.sidebar .sidebar-menu .dropdown-menu li a {
  padding: 8px 10px;
  margin-left: 15px;
  margin-right: 15px;
}
/* BlogList.css */
.data-table .postimage {
  width: 100%;
  height: 18px;
}
.data-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 14px;
  /* text-align: left; */
  margin-left: 0%;
}

.data-table thead tr {
  background-color: #274cb2;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
}

.data-table th,
.data-table td {
  padding: 7px 5px;
  border: 1px solid #dddddd;
}

.data-table tbody tr {
  border-bottom: 1px solid #dddddd;
  box-sizing: border-box;
}
.data-table tbody tr td {
  max-width: 200px;
  word-wrap: break-word;
}
.data-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.data-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.data-table .postimage {
  max-width: 100px;
  height: auto;
}
.editimg {
  width: 13px;
}
#footer {
  margin-top: 50px;
}
footer {
  width: 100%;
  /* bottom: 0; */

  padding: 10px;
  text-align: center;
  border-top: 1px solid #625e5e31;
  box-sizing: border-box;
  margin: 0;
  font-size: 14px;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-size: 12px;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  cursor: pointer;
}

.pagination button.active {
  background-color: #274cb2;
  color: #fff;
  border-color: #274cb2;
}

.pagination button:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
  background-color: #e9ecef;
}

.add-item {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #274cb2;
  border: 1px solid #274cb2;
  color: white;
  font-size: 12px;
}
